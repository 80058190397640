<template>
  <main-layout>
    <v-dialog v-model="dialog">
      <v-form ref="form" v-model="valid" lazy-validation>
        <div class="d-flex activity-schedule-editing">
          <h1>活動予定編集</h1>
        </div>
        <SelectTime
          :date="dataDateFromSelected"
          :hours="getHours"
          :minutes="getMinutes"
          :hour="dataHoursSelected"
          :minute="dataMinutesSelected"
          @setDate="(value) => { dataDateFromSelected = value }"
          @setHour="(value) => { dataHoursSelected = value }"
          @setMinute="(value) => { dataMinutesSelected = value }"
        ></SelectTime>
        <div class="mt-2">
          <h4 class="activity-person">活動担当者</h4>
          <p class="text--title my-username" v-if="userName">
            {{ userName }}
          </p>
          <p class="text--title my-username" v-else>{{ name }}</p>
        </div>
        <div class="mt-2">
          <v-row>
            <v-col :cols="6">
              <template>
                <p class="label">相手</p>
                <v-select
                  dense
                  :items="getEnumParticipantTypeList"
                  v-model="dataParticipantsSelected"
                  multiple
                  class="multi-select"
                  :rules="rules.ruleSelectParticipants"
                  required
                ></v-select>
              </template>
            </v-col>
            <v-col :cols="6">
              <template>
                <p class="label">優先度</p>
                <v-select
                  dense
                  :items="getPriority"
                  item-text="name"
                  item-value="id"
                  v-model="dataPrioritySelected"
                  class="multi-select"
                ></v-select>
              </template>
            </v-col>
          </v-row>
        </div>
        <div>
          <v-row>
            <v-col :cols="6">
              <template>
                <p class="label">手段</p>
                <v-select
                  dense
                  :items="getEnumClientActionMethodList"
                  item-text="name"
                  item-value="id"
                  v-model="dataActionMethodIdSelected"
                  class="multi-select"
                ></v-select>
              </template>
            </v-col>
          </v-row>
        </div>
        <div class="mt-2">
          <h5 class="action">アクション</h5>
          <v-textarea
            outlined
            color="#707070"
            height="110px"
            class="text-area"
            v-model="dataActionSelected"
            :rules="[$rules.checkTextAreaLength()]"
          ></v-textarea>
        </div>
        <div class="d-flex justify-space-between" style="margin-top: 30px">
          <div>
            <v-btn class="t-btn--red-dark mr-4" @click="handleDeleteClientActionHistory"
              >削除</v-btn
            >
          </div>
          <div>
            <v-btn class="t-btn--red-dark mr-4" @click="closeDialog">
              閉じる
            </v-btn>
            <v-btn
              class="t-btn--prm"
              @click="save"
            >
              保存
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-dialog>
  </main-layout>
</template>
<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import MainLayout from '@/layouts/MainLayout';
import moment from 'moment';
import SelectTime from './selectTime.vue';

export default {
  components: {
    MainLayout,
    SelectTime
  },
  name: 'TicketsBuy',
  data() {
    return {
      name: localStorage.getItem("name"),
      dataGroup: [],
      // v-date-picker-custom
      menuDateFrom: false,
      menuDateTo: false,
      dateTo: new Date().toISOString().substr(0, 10),
      valid: true,
      rules: {
        dateFrom: [ v => !!v || '必須項目です。'],
        ruleSelectParticipants: [
          (v) => !!v || '必須項目です。',
          (v) => !(v && v.length === 0) || '必須項目です。',
        ],
      },
      currentUserLoginName: localStorage.getItem('name')
    };
  },
  props: {
    visible: Boolean,
    currentTable1RowId: {
      type: Number,
      default: null,
    },
    userName: String,
  },
  computed: {
    dialog: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.closeDialog();
        }
      },
    },
    ...mapGetters([
      // Const Enum
      'getEnumParticipantTypeList',
      'getEnumClientActionMethodList',
      // Data call API
      'getClientActionHistory',
      // Select default
      'getActionPlannedAt',
      'getHours',
      'getMinutes',
      'getParticipants',
      'getPriority',
      'getActionMethodId',
      'getAction',
      // Selected
      'getActionPlannedAtSelected',
      'getHoursSelected',
      'getMinutesSelected',
      'getParticipantsSelected',
      'getPrioritySelected',
      'getActionMethodIdSelected',
      'getActionSelected',
      'getDateFromSelected',
    ]),
    // v-model selected
    dataDateFromSelected: {
      get() {
        return this.getDateFromSelected;
      },
      set(value) {
        this.setDateFromSelected(value);
      },
    },
    dataActionPlannedAtSelected: {
      get() {
        return this.getActionPlannedAtSelected;
      },
      set(value) {
        this.setActionPlannedAtSelected(value);
      },
    },
    dataHoursSelected: {
      get() {
        return this.getHoursSelected;
      },
      set(value) {
        this.setHoursSelected(value);
      },
    },
    dataMinutesSelected: {
      get() {
        return this.getMinutesSelected;
      },
      set(value) {
        this.setMinutesSelected(value);
      },
    },
    dataParticipantsSelected: {
      get() {
        return this.getParticipantsSelected;
      },
      set(value) {
        this.setParticipantsSelected(value);
      },
    },
    dataPrioritySelected: {
      get() {
        return this.getPrioritySelected;
      },
      set(value) {
        this.setPrioritySelected(value);
      },
    },
    dataActionMethodIdSelected: {
      get() {
        return this.getActionMethodIdSelected;
      },
      set(value) {
        this.setActionMethodIdSelected(value);
      },
    },
    dataActionSelected: {
      get() {
        return this.getActionSelected;
      },
      set(value) {
        this.setActionSelected(value);
      },
    },
  },
  methods: {
    ...mapActions([
      'actionUpdateClientActionHistory',
      'getClientActionHistoryListActivities',
      'getClientActionHistoryListActivitiesHistory',
      'actionDeleteClientActionHistory'
    ]),
    ...mapMutations([
      // Const Enum
      'setActionPlannedAtSelected',
      'setHoursSelected',
      'setMinutesSelected',
      'setParticipantsSelected',
      'setPrioritySelected',
      'setActionMethodIdSelected',
      'setActionSelected',
      'setDateFromSelected',

      // Alert
      'setAlertSuccess',
      'setAlertError',
    ]),
    handleDeleteClientActionHistory() {
      this.actionDeleteClientActionHistory(this.currentTable1RowId).then((result) => {
        this.setAlertSuccess(this.$t('messages.deletedSuccessfully'));
        this.$emit('reloadTable')
        this.closeDialog()
      })
    },

    // Check valid ActionPlannedAtSelected
    checkValidActionPlannedAtSelected () {
      const check = moment(
        new Date(
          `${this.getDateFromSelected && this.getDateFromSelected}${
            this.getHoursSelected && this.getMinutesSelected
              ? ` ${this.getHoursSelected}:${this.getMinutesSelected}`
              : ''
          }`,
        ),
      ).diff(moment());
      // isNaN(check) === true => ActionPlannedAtSelected dateFrom is not select
      if (isNaN(check)) {
        return null
      }
      // check <= 0 => ActionPlannedAtSelected time in the past
      if (check <= 0) {
        return false
      }
      // checkValidActionPlannedAtSelected > 0 => ActionPlannedAtSelected time in the future
      if (check > 0) {
        return true
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        const finishData = {
          id: this.currentTable1RowId,
          actionPlannedAt: this.getDateFromSelected
            ? new Date(
                `${this.getDateFromSelected}${
                  this.getHoursSelected && this.getMinutesSelected
                    ? ` ${this.getHoursSelected}:${this.getMinutesSelected}`
                    : ''
                }`,
              )
            : null,
          participants: this.getParticipantsSelected,
          priority: this.getPrioritySelected,
          actionMethodId: this.getActionMethodIdSelected,
          action: this.getActionSelected,
        };
        this.actionUpdateClientActionHistory(finishData).then(() => {
          this.setAlertSuccess('成功しました。');
          this.$emit('reloadTable')
          this.closeDialog();
        });
      }
    },
    closeDialog() {
      this.setActionPlannedAtSelected(null);
      this.setHoursSelected(null);
      this.setMinutesSelected(null);
      this.setParticipantsSelected(null);
      this.setPrioritySelected(null);
      this.setActionMethodIdSelected(null);
      this.setActionSelected(null);
      this.valid = true;
      this.setDateFromSelected(null);
      this.$refs.form.resetValidation();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
body {
  overflow: hidden;
}
.boder_padding {
  padding: 10px 0 10px 20px;
}
.boder_gray_padding {
  border-bottom: 1px solid gray;
  padding: 10px 0 10px 20px;
}
.label {
  font-size: 12px;
  color: #616161;
  margin: 0;
  font-weight: 600 !important;
}
.multi-select {
  margin: 0;
  padding: 0;
  ::v-deep {
    .v-select__selection--comma {
      font-size: 14px;
      color: #424242;
    }
    .v-icon__svg {
      color: #333333;
    }
    .v-messages__message {
      font-size: 12px !important;
    }
  }
}
.date-text {
  .v-menu__content--fixed {
    .v-select-list {
      display: none !important;
    }
  }
}
::v-deep {
  .date-text {
    .v-menu__content--fixed {
      .v-select-list {
        display: none !important;
      }
    }
  }
  .v-dialog {
    width: 404px;
    min-height: 580px;
    background-color: white;
    padding: 30px;
    border-radius: 9px;
    font-size: 14px;
    .v-label {
      font-size: 14px;
    }
  }
  .w-50 {
    width: 50%;
  }
  .v-counter {
    font-size: 10px;
    color: var(--text__dark) !important;
    font-weight: 600;
  }
  .sort-item {
    max-width: 100px;
  }
  .cancel-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .v-input {
      max-width: 40px;
      padding: 0;
      * {
        text-align: center;
      }
    }
  }
}
.activity-schedule-editing {
  color: #212121;
}
.colon {
  color: #616161;
  font-size: 20px;
  margin-top: 30px !important;
}
.activity-person {
  font-size: 12px;
  color: #212121;
}
.my-username {
  font-size: 16px;
  color: #616161;
}
.action {
  font-size: 10px;
  color: #000000;
  font-weight: 600 !important;
}
.text-area {
  margin-top: 7px;
  min-height: 110px;
  width: 372px;
  border-color: #707070 !important;
  ::v-deep {
    .v-input__control {
      min-height: 110px !important;
    }
    .v-input__slot {
      min-height: 110px;
      margin-bottom: 0px;
    }
    .v-text-field__details {
      padding: 0 !important;
    }
    .v-text-field__slot {
      textarea {
        margin: 0 !important;
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
  }
}
.combobox-icon {
  ::v-deep {
    .v-icon {
      margin-bottom: 8px;
      color: #333333;
      cursor: pointer;
    }
  }
}
.input-pos .v-select__slot {
    input[role="button"] {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      font-size: 14px !important;
      color: #424242;
    }
    .v-input__append-inner {
      i {
        font-size: 24px !important;
      }
    } 
  }
</style>