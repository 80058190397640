<template>
  <div class="mt-2">
    <v-row>
      <v-col :cols="6">
        <template>
          <p class="label">行動予定日</p>
          <div class="mt-1">
            <v-menu
              v-model="menuDateFrom"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              class="pb-0 mb-0"
            >
              <template v-slot:activator="{ on, attrs }">
                <div class="d-flex align-center">
                  <v-combobox
                    :value="date"
                    readonly
                    dense
                    v-bind="attrs"
                    v-on="on"
                    class="text-size-normal pt-0 combobox-icon input-pos"
                    :append-icon="
                      attrs['aria-expanded'] === 'true'
                        ? 'arrow_drop_up'
                        : 'arrow_drop_down'
                    "
                    :rules="rules.dateFrom"
                  ></v-combobox>
                </div>
              </template>
              <v-date-picker
                v-model="dateSelect"
                :first-day-of-week="0"
                :locale="$i18n.locale"
                scrollable
                @input="menuDateFrom = false"
                class="v-date-picker-custom"
                :min="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
              ></v-date-picker>
            </v-menu>
          </div>
        </template>
      </v-col>
      <v-col :cols="2.9">
        <template>
          <p class="label">時間</p>
          <v-select
            :items="hours"
            item-text="number"
            item-value="number"
            :disabled="date ? false : true"
            v-model="hourSelect"
            class="multi-select"
            :rules="date ? [ruleCheckValidHours] : []"
          ></v-select>
        </template>
      </v-col>
      <h1 class="mt-5 colon">:</h1>
      <v-col :cols="2.9">
        <template>
          <p class="label">分</p>
          <v-select
            :items="minutes"
            item-text="number"
            item-value="number"
            :disabled="date ? false : true"
            v-model="minuteSelect"
            class="multi-select"
            :rules="date ? [ruleCheckValidMinutes] : []"
          ></v-select>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import { getHourDiffTwoDates } from '@/constants/functions';

export default {
  name: 'InputDate',
  data() {
    return {
      menuDateFrom: false,
      rules: {
        dateFrom: [(v) => !!v || '必須項目です。'],
      },
    };
  },

  computed: {
    dateSelect: {
      get() {
        return this.date;
      },
      set(value) {
        this.$emit('setDate', value);
      },
    },

    hourSelect: {
      get() {
        return this.hour;
      },
      set(value) {
        this.$emit('setHour', value);
      },
    },

    minuteSelect: {
      get() {
        return this.minute;
      },
      set(value) {
        this.$emit('setMinute', value);
      },
    },

    ruleCheckValidMinutes() {
      if (this.minute) {
        const current = moment(new Date()).format('YYYY-MM-DD HH:mm');
        const date = `${this.date} ${this.hour}:${this.minute}`;
        if (getHourDiffTwoDates(current, date)) {
          return true;
        }
        return '未来の日時を指定して下さい';
      } else {
        return '必須項目です。';
      }
    },

    ruleCheckValidHours() {
      if (this.hour) {
        const current = moment(new Date());
        if (parseInt(current.hours()) === parseInt(this.hour)) {
          return true;
        }
        const date = `${this.date} ${this.hour}:${
          this.minute ? this.minute : '00'
        }`;
        if (getHourDiffTwoDates(current.format('YYYY-MM-DD HH:mm'), date)) {
          return true;
        }
        return '未来の日時を指定して下さい';
      } else {
        return '必須項目です。';
      }
    },
  },
  props: {
    date: String,
    hours: Array,
    minutes: Array,
    hour: String,
    minute: String,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.label {
  font-size: 12px;
  color: #616161;
  margin: 0;
}
.multi-select {
  margin: 0;
  padding: 0;
  ::v-deep {
    .v-select__selection--comma {
      font-size: 12px;
      color: #424242;
    }
    .v-icon__svg {
      color: #333333;
    }
    .v-messages__message {
      font-size: 12px !important;
    }
  }
}
.colon {
  color: #616161;
  font-size: 20px;
  margin-top: 30px !important;
}
.combobox-icon {
  ::v-deep {
    .v-icon {
      margin-bottom: 8px;
      color: #333333;
      cursor: pointer;
    }
  }
}
</style>
